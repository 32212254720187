import {
  createStore,
  useStore,
  SelectorFunction,
  camelCase,
  startCase,
  isString,
  setStorageValue,
  getStorageValue,
} from '@veraio/core';
import parse from 'html-react-parser';
import { config, isConfigured, DEFAULT_LANG } from './config';
import { getAllTranslations } from './services';
import { getBrowserLanguage, replaceData } from './utils';
import { Language, ObjectString, TranslationsStoreState } from './interfaces';

const LANGUAGE_KEY = 'language';

const defaultState: TranslationsStoreState = {
  isLoading: true,
  language: null,
  currentTranslations: null,
  allTranslations: null,
  allEnvLanguages: null,
  changeLanguage: (_code: string | Language) => {},
  getText: (key: string, _data?: ObjectString) => camelCase(key),
  getDynamicTranslation: (allTranslations: ObjectString[], _languageCodeKey?: string) =>
    allTranslations[0] as ObjectString,
};

const translationsStore = createStore(defaultState);

/**
 * Initialize strank translations store if the configuration is done\
 * If there is error on api call it will return the error
 *
 * @example
 *
 * initTranslationsStore()
 */
export const initTranslationsStore = async (): Promise<any> => {
  if (!isConfigured('initTranslationsStore')) return;

  const languageCode = getBrowserLanguage(config?.defaultLanguage ?? DEFAULT_LANG);

  const [res, err] = await getAllTranslations(config.environment);
  if (!res) return err;

  const envLanguage = Object.keys(res.translations)[0] as string;
  const currentTranslations = (res.translations[languageCode] ?? res.translations[envLanguage]) as ObjectString;
  translationsStore.setState({
    isLoading: false,
    language: res.languages?.find((el: Language) => el.code === languageCode) ?? null,
    currentTranslations,
    allTranslations: res.translations,
    allEnvLanguages: res.languages,
    changeLanguage,
    getText,
    getDynamicTranslation,
  });
};

/**
 * Method to change the language and all translations based on that language\
 * If the language is changed to something that is not valid language it will use the first env language
 *
 * @param lang language code or language object for selected language
 * @example
 *
 * const language = useTranslations(translationsState => translationsState.language) // { code: 'en', name: 'English', ... }
 * changeLanguage('bg')
 * console.log(language) // { code: 'bg', name: 'Bulgarian', ... }
 * changeLanguage({ code: 'en', name: 'English', ... })
 * console.log(language) // { code: 'en', name: 'English', ... }
 */
export const changeLanguage = (lang: string | Language): void => {
  if (!isConfigured('changeLanguage')) return;

  const { allTranslations, allEnvLanguages } = translationsStore.getState();
  const code = isString(lang) ? lang : lang?.code;
  const foundLanguage = (allEnvLanguages?.find((el: Language) => el?.code === code) ??
    (allEnvLanguages && allEnvLanguages[0])) as Language;
  setStorageValue(LANGUAGE_KEY, foundLanguage?.code, config.storage);
  translationsStore.setState((prev) => ({
    ...prev,
    language: foundLanguage,
    currentTranslations: allTranslations ? (allTranslations[foundLanguage?.code] as ObjectString) : null,
  }));
};

/**
 * Method used to obtain a translation for specific key.\
 * If the config is with set parseHtml to true it will return dom node for html valid tags\
 * If the translation key do not persists it will return the key converted to startCase\
 * Every single one key is converted to camelCase
 *
 * @param key translation name inside strank
 * @param data object with properties for insert data inside the translation
 * @returns {string | JSX.Element} string of the chosen translation or react child if it is html element
 * @example
 * [{ key: 'asd', value: 'Asd' }, { key: 'hello', value: 'Hello {name}' }, { key: 'html', value: 'HTML <h1>{title}</h1>' }]
 *
 * getText('asd') // 'Asd'
 * getText('hello', { name: 'John' }) // 'Hello John'
 * getText('hello', { name: 'Patrick' }) // 'Hello Patrick'
 * getText('html', { title: 'Page' }) // HTML <h1>Page</h1>
 */
export const getText = (key: string, data?: ObjectString): string | JSX.Element | JSX.Element[] => {
  if (!isConfigured('getText')) return key;

  const { currentTranslations } = translationsStore.getState();
  const textData = currentTranslations?.[camelCase(key)] as string;
  const translation = textData ? replaceData(textData, data) : textData;
  return translation ? (config.parseHtml ? parse(translation) : translation) : startCase(key);
};

/**
 * We had translations stored into DB of API's which are for specific domain logic\
 * Those translations should be extracted based on the user language with this method
 *
 * @param allTranslations an array of different translations on different languages
 * @param languageCodeKey name of the property which indicates the language code
 * @returns {object} object for choses user language if persisted or first one
 * @example
 * { language: 'bg', ... }
 *
 * getDynamicTranslation([{ languageCode: 'en', name: 'John' }, { languageCode: 'bg', name: 'Gosho' }]) // { languageCode: 'bg', name: 'Gosho' }
 * getDynamicTranslation([{ languageCode: 'en', name: 'John' }, { languageCode: 'ar', name: 'Timi' }]) // { languageCode: 'en', name: 'John' }
 */
export const getDynamicTranslation = (
  allTranslations: ObjectString[],
  languageCodeKey = 'languageCode',
): ObjectString => {
  const firstTranslation = allTranslations[0] as ObjectString;

  if (!isConfigured('getDynamicTranslation')) return firstTranslation;

  const { language } = translationsStore.getState();

  const translation = allTranslations?.find((el) => el[languageCodeKey] === language?.code);
  const defaultTranslation = allTranslations?.find((el) => el[languageCodeKey] === DEFAULT_LANG);

  return translation ?? defaultTranslation ?? firstTranslation;
};

/**
 * Get the code of latest used user language
 *
 * @returns {string | null} saved into storage language
 * @example
 *
 * getSavedLanguage() // 'en'
 * getSavedLanguage() // null
 */
export const getSavedLanguage = (): string | null => {
  if (!isConfigured('getSavedLanguage')) return null;

  return getStorageValue(LANGUAGE_KEY, config.storage);
};

/**
 * React hook to use the store inside components with selector method for subscribe to changes for part of the state
 *
 * @param callback function that accept the hole state as argument and return a part of that state which need to be changed for a re-render
 * @returns {TranslationsStoreState} object for environment translations
 * @example
 *
 * useTranslations(state => state.isLoading) // this component will re-render only when there is change on isLoading property
 */
export const useTranslations = (callback: SelectorFunction<TranslationsStoreState>): TranslationsStoreState => {
  if (!isConfigured('useTranslations')) return defaultState;

  return useStore(translationsStore, callback);
};
