/* eslint-disable no-control-regex */
import { isString } from './lodash';

/** Used to compose unicode character classes. */
const rsAstralRange = '\\ud800-\\udfff';
const rsComboMarksRange = '\\u0300-\\u036f';
const reComboHalfMarksRange = '\\ufe20-\\ufe2f';
const rsComboSymbolsRange = '\\u20d0-\\u20ff';
const rsComboMarksExtendedRange = '\\u1ab0-\\u1aff';
const rsComboMarksSupplementRange = '\\u1dc0-\\u1dff';
const rsComboRange =
  rsComboMarksRange +
  reComboHalfMarksRange +
  rsComboSymbolsRange +
  rsComboMarksExtendedRange +
  rsComboMarksSupplementRange;
const rsDingbatRange = '\\u2700-\\u27bf';
const rsLowerRange = 'a-z\\xdf-\\xf6\\xf8-\\xff';
const rsMathOpRange = '\\xac\\xb1\\xd7\\xf7';
const rsNonCharRange = '\\x00-\\x2f\\x3a-\\x40\\x5b-\\x60\\x7b-\\xbf';
const rsPunctuationRange = '\\u2000-\\u206f';
const rsSpaceRange =
  ' \\t\\x0b\\f\\xa0\\ufeff\\n\\r\\u2028\\u2029\\u1680\\u180e\\u2000\\u2001\\u2002\\u2003\\u2004\\u2005\\u2006\\u2007\\u2008\\u2009\\u200a\\u202f\\u205f\\u3000';
const rsUpperRange = 'A-Z\\xc0-\\xd6\\xd8-\\xde';
const rsVarRange = '\\ufe0e\\ufe0f';
const rsBreakRange = rsMathOpRange + rsNonCharRange + rsPunctuationRange + rsSpaceRange;

/** Used to compose unicode capture groups. */
const rsApos = "['\u2019]";
const rsBreak = `[${rsBreakRange}]`;
const rsCombo = `[${rsComboRange}]`;
const rsDigit = '\\d';
const rsDingbat = `[${rsDingbatRange}]`;
const rsLower = `[${rsLowerRange}]`;
const rsMisc = `[^${rsAstralRange}${rsBreakRange + rsDigit + rsDingbatRange + rsLowerRange + rsUpperRange}]`;
const rsFitz = '\\ud83c[\\udffb-\\udfff]';
const rsModifier = `(?:${rsCombo}|${rsFitz})`;
const rsNonAstral = `[^${rsAstralRange}]`;
const rsRegional = '(?:\\ud83c[\\udde6-\\uddff]){2}';
const rsSurrPair = '[\\ud800-\\udbff][\\udc00-\\udfff]';
const rsUpper = `[${rsUpperRange}]`;
const rsZWJ = '\\u200d';

/** Used to compose unicode regexes. */
const rsMiscLower = `(?:${rsLower}|${rsMisc})`;
const rsMiscUpper = `(?:${rsUpper}|${rsMisc})`;
const rsOptContrLower = `(?:${rsApos}(?:d|ll|m|re|s|t|ve))?`;
const rsOptContrUpper = `(?:${rsApos}(?:D|LL|M|RE|S|T|VE))?`;
const reOptMod = `${rsModifier}?`;
const rsOptVar = `[${rsVarRange}]?`;
const rsOptJoin = `(?:${rsZWJ}(?:${[rsNonAstral, rsRegional, rsSurrPair].join('|')})${rsOptVar + reOptMod})*`;
const rsOrdLower = '\\d*(?:1st|2nd|3rd|(?![123])\\dth)(?=\\b|[A-Z_])';
const rsOrdUpper = '\\d*(?:1ST|2ND|3RD|(?![123])\\dTH)(?=\\b|[a-z_])';
const rsSeq = rsOptVar + reOptMod + rsOptJoin;
const rsEmoji = `(?:${[rsDingbat, rsRegional, rsSurrPair].join('|')})${rsSeq}`;

const reUnicodeWords = RegExp(
  [
    `${rsUpper}?${rsLower}+${rsOptContrLower}(?=${[rsBreak, rsUpper, '$'].join('|')})`,
    `${rsMiscUpper}+${rsOptContrUpper}(?=${[rsBreak, rsUpper + rsMiscLower, '$'].join('|')})`,
    `${rsUpper}?${rsMiscLower}+${rsOptContrLower}`,
    `${rsUpper}+${rsOptContrUpper}`,
    rsOrdUpper,
    rsOrdLower,
    `${rsDigit}+`,
    rsEmoji,
  ].join('|'),
  'g',
);

const hasUnicodeWord = RegExp.prototype.test.bind(/[a-z][A-Z]|[A-Z]{2}[a-z]|[0-9][a-zA-Z]|[a-zA-Z][0-9]|[^a-zA-Z0-9 ]/);

/** Used to match words composed of alphanumeric characters. */
const reAsciiWord = /[^\x00-\x2f\x3a-\x40\x5b-\x60\x7b-\x7f]+/g;

const asciiWords = (string: string) => [...(string.match(reAsciiWord) ?? [])];

/**
 * Splits a Unicode `string` into an array of its words.
 *
 * @param {string} The string to inspect.
 * @returns {Array} Returns the words of `string`.
 */
export const unicodeWords = (string: string): string[] =>
  isString(string) ? [...(string.match(reUnicodeWords) ?? [])] : [];

/**
 * Splits `string` into an array of its words.
 *
 * @param {string} [string=''] The string to inspect.
 * @param {RegExp|string} [pattern] The pattern to match words.
 * @returns {Array} Returns the words of `string`.
 * @example
 *
 * words('fred, barney, & pebbles') // ['fred', 'barney', 'pebbles']
 * words('fred, barney, & pebbles', /[^, ]+/g) // ['fred', 'barney', '&', 'pebbles']
 */
export const words = (string: string, pattern?: RegExp | string): string[] => {
  if (!isString(string)) return [];

  if (pattern === undefined) return hasUnicodeWord(string) ? unicodeWords(string) : asciiWords(string);

  return [...(string.match(pattern) ?? [])];
};

/**
 * Converts `string` to first letter upper case and rest to lower case
 *
 * @param {string} [string=''] The string to convert.
 * @returns {string} Returns the upper cased string.
 * @see camelCase, lowerCase, kebabCase, snakeCase, upperCase, upperFirst
 * @example
 *
 * upperFirst('fooBar') // 'Foobar'
 */
export const upperFirst = (string: string): string =>
  isString(string) ? `${string[0]?.toUpperCase()}${string.substring(1)}` : '';

/**
 * Converts `string` to
 * [start case](https://en.wikipedia.org/wiki/Letter_case#Stylistic_or_specialised_usage).
 *
 * @param {string} [string=''] The string to convert.
 * @returns {string} Returns the start cased string.
 * @see camelCase, lowerCase, kebabCase, snakeCase, upperCase, upperFirst
 * @example
 *
 * startCase('--foo-bar--') // 'Foo Bar'
 * startCase('fooBar') // 'Foo Bar'
 * startCase('__FOO_BAR__') // 'FOO BAR'
 */
export const startCase = (string: string): string =>
  isString(string)
    ? words(string.replace(/['\u2019]/g, '')).reduce(
        (result, word, index) => result + (index ? ' ' : '') + upperFirst(word),
        '',
      )
    : '';

/**
 * Converts `string` to [camel case](https://en.wikipedia.org/wiki/CamelCase).
 *
 * @param {string} [string=''] The string to convert.
 * @returns {string} Returns the camel cased string.
 * @see lowerCase, kebabCase, snakeCase, startCase, upperCase, upperFirst
 * @example
 *
 * camelCase('Foo Bar') // 'fooBar'
 * camelCase('--foo-bar--') // 'fooBar'
 * camelCase('__FOO_BAR__') // 'fooBar'
 */
export const camelCase = (string: string): string =>
  isString(string)
    ? words(string.replace(/['\u2019]/g, '')).reduce((result, word, index) => {
        word = word.toLowerCase();
        return result + (index ? upperFirst(word) : word);
      }, '')
    : '';

/**
 * Converts `string` to
 * [kebab case](https://en.wikipedia.org/wiki/Letter_case#Special_case_styles).
 *
 * @param {string} [string=''] The string to convert.
 * @returns {string} Returns the kebab cased string.
 * @see camelCase, lowerCase, snakeCase, startCase, upperCase, upperFirst
 * @example
 *
 * kebabCase('Foo Bar') // 'foo-bar'
 * kebabCase('fooBar') // 'foo-bar'
 * kebabCase('__FOO_BAR__') // 'foo-bar'
 */
export const kebabCase = (string: string): string =>
  isString(string)
    ? words(string.replace(/['\u2019]/g, '')).reduce(
        (result, word, index) => result + (index ? '-' : '') + word.toLowerCase(),
        '',
      )
    : '';

/**
 * Converts `string` to
 * [snake case](https://en.wikipedia.org/wiki/Letter_case#Special_case_styles).
 *
 * @param {string} [string=''] The string to convert.
 * @returns {string} Returns the snake cased string.
 * @see camelCase, lowerCase, snakeCase, startCase, upperCase, upperFirst
 * @example
 *
 * snakeCase('Foo Bar') // 'foo_bar'
 * snakeCase('fooBar') // 'foo_bar'
 * snakeCase('__FOO_BAR__') // 'foo_bar'
 */
export const snakeCase = (string: string): string =>
  isString(string)
    ? words(string.replace(/['\u2019]/g, '')).reduce(
        (result, word, index) => result + (index ? '_' : '') + word.toLowerCase(),
        '',
      )
    : '';

/**
 * Concat multiple arguments into one string by joining them with separator
 *
 * @param string stringified json
 * @param defaultValue default value if parse can not be done
 * @returns json object
 *
 * @example
 * concatStrings(false && 'className1', 'className2') // 'className2'
 * concatStrings(true && 'className1', 'className2') // 'className1 className2'
 * concatStrings(null, undefined, 2, 'hello') // '2 hello'
 * concatStrings({}, []) // '[object Object] '
 */
export const concatStrings = (...args: any[]): string => args.filter(Boolean).join(' ');

/**
 * Convert any string to base64, including non unicode strings
 * If non string parameter is passed it will return empty string
 *
 * @param string any string with some characters inside of it
 * @returns base64 encoded string
 *
 * @example
 * encodeBase64('Hello world') // 'SGVsbG8gd29ybGQ='
 * encodeBase64('✓ à la mode') // '4pyTIMOgIGxhIG1vZGU='
 * encodeBase64(false) // ''
 * encodeBase64({}) // ''
 */
export const encodeBase64 = (string: string): string => {
  if (!isString(string)) return '';

  return btoa(
    encodeURIComponent(string).replace(/%([0-9A-F]{2})/g, (_, character) =>
      String.fromCharCode(parseInt(`0x${character}`, 16)),
    ),
  );
};

/**
 * Convert any base64 encoded string to string, including non unicode strings
 * If non string parameter is passed it will return empty string
 *
 * @param string any base64 encoded string with some characters inside of it
 * @returns decoded string
 *
 * @example
 * decodeBase64('SGVsbG8gd29ybGQ=') // 'Hello world'
 * decodeBase64('4pyTIMOgIGxhIG1vZGU=') // '✓ à la mode'
 * decodeBase64(false) // ''
 * decodeBase64({}) // ''
 */
export const decodeBase64 = (string: string): string => {
  if (!isString(string)) return '';

  return decodeURIComponent(
    atob(string)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );
};
