import { createStore, useStore, SelectorFunction } from '@veraio/core';
import { isConfigured } from './config';
import { getAllLanguages } from './services';
import { Language } from './interfaces';

const defaultState: Language[] = [];
const languagesStore = createStore(defaultState);

/**
 * Initialize all strank languages store
 *
 * @example
 *
 * initLanguagesStore()
 */
export const initLanguagesStore = async (): Promise<void> => {
  if (!isConfigured('initLanguagesStore')) return;

  const [res, err] = await getAllLanguages();
  if (!res) return err;

  languagesStore.setState(res);
};

/**
 * Search for language object by language code string
 *
 * @param languageCode language code for desired language object
 * @returns {Language | undefined} language object of matched language code or undefined if not found
 * @example
 *
 * getLanguageByCode('en') // { name: 'English', nativeName: 'English', code: 'en' }
 * getLanguageByCode('asd') // undefined
 */
export const getLanguageByCode = (languageCode: string): Language | undefined => {
  if (!isConfigured('getLanguageByCode')) return;

  return languagesStore.getState().find((el: Language) => el.code === languageCode);
};

/**
 * React hook to use the store inside components with selector method for subscribe to changes for part of the state
 *
 * @param callback function that accept the hole state as argument and return a part of that state which need to be changed for a re-render
 * @returns {Language[]} array of all languages defined inside strank
 * @example
 *
 * useLanguages(state => state[2]) // this component will re-render only when there is change inside 3rd language
 */
export const useLanguages = (callback: SelectorFunction<Language[]>): Language[] => {
  if (!isConfigured('useLanguages')) return defaultState;

  return useStore(languagesStore, callback);
};
