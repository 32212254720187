import { createStore, useStore, SelectorFunction, getStorageValue, setStorageValue, isNumber } from '@veraio/core';
import { uniqBy, sortBy } from 'lodash-es';
import { config, isConfigured } from '../config';
import { getGeoLocation as getGeoLocationService, getContinents, getManagerCountries } from '../services';
import { LocationsStore, Country, LocationsStoreOptions, Continent } from '../interfaces';

const SHIPPING_FROM_KEY = 'shippingFrom';
const SHIPPING_TO_KEY = 'shippingTo';

const defaultValue: LocationsStore = {
  geoLocation: null,
  continents: null,
  managerCountries: null,
  countries: null,
  shippingFrom: [],
  shippingTo: [],
};

const locationsStore = createStore(defaultValue);

/**
 * Initialize locations store with this method. It will perform requests to get geolocation and continents\
 * If there is error returned from API it will be returned from this method
 *
 * @returns {Promise} resolve with error coming from API
 * @example
 * initLocationsStore()
 * initLocationsStore({ isManager: true }) // will fetch additionally all managers countries
 */
export const initLocationsStore = async (options?: LocationsStoreOptions): Promise<any> => {
  if (!isConfigured('initLocationsStore')) return;

  const countriesPromise: Promise<[Country[] | Continent[] | null, any]> = options?.isManager
    ? getManagerCountries()
    : getContinents();

  const [[geoLocationResponse, geoLocationError], [continentsResponse, continentsError]] = await Promise.all([
    getGeoLocationService(),
    countriesPromise,
  ]);

  const countries = options?.isManager
    ? sortBy(continentsResponse ?? [], 'name')
    : sortBy(continentsResponse?.flatMap((el) => (el as Continent).countries) ?? [], 'name');

  locationsStore.setState((prev) => ({
    ...prev,
    shippingFrom: getStorageValue(SHIPPING_FROM_KEY, config.storage) ?? [],
    shippingTo: getStorageValue(SHIPPING_TO_KEY, config.storage) ?? [],
    // geoLocation: { isCryptoRestricted: true },
    geoLocation: geoLocationResponse,
    continents: options?.isManager ? [] : (continentsResponse as Continent[]),
    countries: countries as Country[],
  }));

  return geoLocationError ?? continentsError;
};

/**
 * Change the shippingFrom and shippingTo locations for all deals which the user see
 *
 * @param locationsFrom array of all locations for shippingFrom
 * @param locationsTo array of all locations for shippingTo
 * @see Country
 * @example
 *
 * setShipping([CountryIdA, CountryIdB], [CountryC])
 */
export const setShipping = (locationsFrom: Country[], locationsTo: Country[]): void => {
  if (!isConfigured('setShipping')) return;

  setStorageValue(SHIPPING_FROM_KEY, locationsFrom, config.storage);
  setStorageValue(SHIPPING_TO_KEY, locationsTo, config.storage);
  locationsStore.setState((prev) => ({ ...prev, shippingFrom: locationsFrom, shippingTo: locationsTo }));
};

/**
 * Change the shippingFrom locations for all deals which the user see
 *
 * @param locations array of all locations for shippingFrom
 * @see Country
 * @example
 *
 * setShippingFrom([CountryIdA, CountryIdB])
 */
export const setShippingFrom = (locations: Country[]): void => {
  if (!isConfigured('setShippingFrom')) return;

  setStorageValue(SHIPPING_FROM_KEY, locations, config.storage);
  locationsStore.setState((prev) => ({ ...prev, shippingFrom: locations }));
};

/**
 * Change the shippingTo locations for all deals which the user see
 *
 * @param locations array of all locations for shippingTo
 * @see Country
 * @example
 *
 * setShippingTo([CountryIdA, CountryIdB])
 */
export const setShippingTo = (locations: Country[]): void => {
  if (!isConfigured('setShippingTo')) return;

  setStorageValue(SHIPPING_TO_KEY, locations, config.storage);
  locationsStore.setState((prev) => ({ ...prev, shippingTo: locations }));
};

/**
 * Add new location to shippingTo locations
 *
 * @param location object of location for shippingFrom to be added
 * @see Country
 * @example
 *
 * addShippingFrom({id: 15, name: 'Bulgaria'})
 */
export const addShippingFrom = (location: Country | Country[]): void => {
  if (!isConfigured('addShippingFrom')) return;

  const locations = uniqBy(locationsStore.getState().shippingFrom?.concat(location), 'id');
  setStorageValue(SHIPPING_FROM_KEY, locations, config.storage);
  locationsStore.setState((prev) => ({ ...prev, shippingFrom: locations ?? [] }));
};

/**
 * Add new location to shippingTo locations
 *
 * @param location object of location for shippingTo to be added
 * @see Country
 * @example
 *
 * addShippingTo({id: 15, name: 'Bulgaria'})
 */
export const addShippingTo = (location: Country | Country[]): void => {
  if (!isConfigured('addShippingTo')) return;

  const locations = uniqBy(locationsStore.getState().shippingTo?.concat(location), 'id');
  setStorageValue(SHIPPING_TO_KEY, locations, config.storage);
  locationsStore.setState((prev) => ({ ...prev, shippingTo: locations ?? [] }));
};

/**
 * Remove location from shippingFrom locations
 *
 * @param location id number or location object of the location which to be removed from shippingFrom locations
 * @see Country
 * @example
 *
 * removeShippingFrom(id15)
 * removeShippingFrom({ id: 15, name: 'Bulgaria'})
 */
export const removeShippingFrom = (location: number | Country): void => {
  if (!isConfigured('removeShippingFrom')) return;

  const locations = locationsStore
    .getState()
    .shippingFrom?.filter((el) => el.id !== (isNumber(location) ? location : location.id));
  setStorageValue(SHIPPING_FROM_KEY, locations, config.storage);
  locationsStore.setState((prev) => ({ ...prev, shippingFrom: locations ?? [] }));
};

/**
 * Remove location from shippingTo locations
 *
 * @param location id number or location object of the location which to be removed from shippingTo locations
 * @see Country
 * @example
 *
 * removeShippingTo(id15)
 * removeShippingTo({ id: 15, name: 'Bulgaria'})
 */
export const removeShippingTo = (location: number | Country): void => {
  if (!isConfigured('removeShippingTo')) return;

  const locations = locationsStore
    .getState()
    .shippingTo?.filter((el) => el.id !== (isNumber(location) ? location : location.id));
  setStorageValue(SHIPPING_TO_KEY, locations, config.storage);
  locationsStore.setState((prev) => ({ ...prev, shippingTo: locations ?? [] }));
};

/**
 * Get locations state object only without a subscribe for changes
 *
 * @returns {LocationsStore} state object
 * @example
 * getLocationsState() // Locations Store object
 *
 * @see LocationsStore
 */
export const getLocationsState = (): LocationsStore | null => {
  if (!isConfigured('getLocationsState')) return null;

  return locationsStore.getState();
};

/**
 * Refetch all countries for state update to the last version given from API
 *
 * @returns {Promise} promise which will be resolved with the error from request
 * @example
 *
 * refetchCountries() // will refetch the countries and will update the state with new value
 */
export const refetchCountries = async (): Promise<any> => {
  if (!isConfigured('refetchCountries')) return;

  const [continentsResponse, continentsError] = await getContinents();
  const countries = (continentsResponse?.flatMap((e) => e?.countries)?.filter(Boolean) ?? []) as Country[];
  locationsStore.setState((prev) => ({
    ...prev,
    continents: continentsResponse,
    countries: sortBy(countries, 'name'),
  }));

  return continentsError;
};

/**
 * React hook to use the store inside components with selector method for subscribe to changes for part of the state
 *
 * @param callback function that accept the hole state as argument and return a part of that state which need to be changed for a re-render
 * @example
 *
 * useLocations(state => state.geoLocation) // this component will re-render only when there is change inside geoLocation
 * useLocations(state => state.shipFrom) // this component will re-render only when there is change inside shipFrom
 */
export const useLocations = (callback: SelectorFunction<LocationsStore>): LocationsStore | null => {
  if (!isConfigured('useCurrencies')) return null;

  return useStore(locationsStore, callback);
};
