import { parseJson, stringifyJson } from './lodash';

/**
 * We often use local storage or session storage to save some data
 * A common problem is when you try to store array, object or other structure
 * Natively it does not support those structures, it can store only string as value
 * So we often use JSON.stringify and JSON.parse to store those kinds of structures
 * Which is not needed anymore, we can use getStorageValue, setStorageValue and removeStorageValue
 *
 * @param key string for the key used inside the storage
 * @param storage an object with interface similar to localStorage or SessionStorage
 * @returns retrieved from the chosen storage value
 * @example
 *
 * getStorageValue('user') // { username: 'John', email: 'doe@example.com', ... }
 */
export const getStorageValue = <T>(key: string, storage = window.localStorage): T | null => {
  const storageValue = storage.getItem(key);
  return storageValue && parseJson(storageValue);
};

/**
 * We often use local storage or session storage to save some data
 * A common problem is when you try to store array, object or other structure
 * Natively it does not support those structures, it can store only string as value
 * So we often use JSON.stringify and JSON.parse to store those kinds of structures
 * Which is not needed anymore, we can use getStorageValue, setStorageValue and removeStorageValue
 *
 * @param key string for the key used inside the storage
 * @param storage an object with interface similar to localStorage or SessionStorage
 * @example
 *
 * setStorageValue({ username: 'John', email: 'doe@example.com', ... }) // it will store it inside localStorage
 */
export const setStorageValue = (key: string, value: any, storage = window.localStorage): void => {
  const storageValue = stringifyJson(value);
  storageValue && storage.setItem(key, storageValue);
};

/**
 * We often use local storage or session storage to save some data
 * A common problem is when you try to store array, object or other structure
 * Natively it does not support those structures, it can store only string as value
 * So we often use JSON.stringify and JSON.parse to store those kinds of structures
 * Which is not needed anymore, we can use getStorageValue, setStorageValue and removeStorageValue
 *
 * @param key string for the key used inside the storage
 * @param storage an object with interface similar to localStorage or SessionStorage
 * @example
 *
 * getStorageValue('user') // null
 * setStorageValue({ username: 'John', email: 'doe@example.com', ... }) // it will store it inside localStorage
 * getStorageValue('user') // { username: 'John', email: 'doe@example.com', ... }
 * removeStorageValue('user');
 * getStorageValue('user') // null
 */
export const removeStorageValue = (key: string, storage = window.localStorage): void => storage.removeItem(key);
