import { isNil } from '@veraio/core';
import { ObjectString } from './interfaces';

export const getBrowserLanguage = (defaultLanguage: string) => {
  const browserLanguage = window.navigator.languages ? window.navigator?.languages[0] : window.navigator.language;
  const languageExist = browserLanguage?.split('-')[0];

  return languageExist ?? defaultLanguage;
};

export const replaceData = (string: string, data: ObjectString = {}) => {
  let newString = string;
  const matches = [...string.matchAll(/{\w+}/g)];

  matches.forEach(([match]) => {
    const key = match.substring(1, match.length - 1);
    if (!isNil(data[key])) newString = newString.replace(match, data[key] as string);
  });

  return newString;
};
