/* eslint-disable camelcase */
import qs from 'query-string';
import { isNumber, pick, getStorageValue, setStorageValue, removeStorageValue, decodeBase64 } from '@veraio/core';
import { TokenResponse, JWT } from './interfaces';
import { AUTH_EVENTS_TYPES } from './enums';
import {
  callbackListeners,
  xFromHeader,
  isConfigured,
  requestInstance,
  identityConfig,
  storageKey,
  storageInstance,
} from './config';

export let refreshTokenListener: ReturnType<typeof setTimeout>;

/**
 * Retrieve the token data that is inside storage
 *
 * @returns {TokenResponse | null} saved into storage token or null if it is missing
 * @example
 *
 * getToken() // null
 * setToken('jwtToken')
 * getToken() // 'jwtToken'
 */
export const getToken = (): TokenResponse | null => {
  const token = getStorageValue<string>(storageKey, storageInstance);
  if (!token) return null;

  try {
    return JSON.parse(token);
  } catch (err) {
    softLogout();
    console.error(err);
    return null;
  }
};

/**
 * Save new token into storage which is configures through setAuthConfig({ storage: AsyncStorage })
 *
 * @param token object with all 3 tokens - access, id and refresh
 * @example
 *
 * setToken({ access_token: 'access', id_token: 'id_token' })
 */
export const setToken = (token: TokenResponse): void => {
  setStorageValue(storageKey, JSON.stringify(token), storageInstance);
  registerRefreshTokenListener();
  callbackListeners[AUTH_EVENTS_TYPES.SET_TOKEN](token);
};

/**
 * Renew access, id and refresh tokens for your user. It will set the new token into storage and reinitialize the token listeners\
 * Those token listeners will call refreshTokensSet after token expiration time minus 3 minutes\
 * If there is valid token into storage it will return it and will reinitialize the refresh token listeners
 *
 * @returns {TokenResponse | null} response retrieved on success or null on failure
 * @event AUTH_EVENTS_TYPES.REFRESH_TOKEN is fired with token param before the return clause after successfully retrieved token
 * @example
 *
 * const token = await refreshTokensSet() // if request is successful or there is already active token it will return object if not it will return null
 */
const refreshTokensSet = async (): Promise<TokenResponse | null> => {
  if (!isConfigured('refreshToken')) return null;

  const tokenData = getToken();
  if (!tokenData) return null;
  if (isTokenValid(60000)) {
    setToken(tokenData);
    return tokenData;
  }

  const [refreshTokenUrl, refreshTokenParams] = identityConfig.refreshToken();
  const body = qs.stringify({
    ...pick(identityConfig, ['client_id']),
    request_type: 'si:s',
    refresh_token: tokenData?.refresh_token,
    grant_type: 'refresh_token',
    ...refreshTokenParams,
  });

  const [res, err] = await requestInstance.post<TokenResponse>(refreshTokenUrl, body, xFromHeader);

  if (!res?.access_token) {
    softLogout();
    console.error('Unable to refresh token, the response of refresh request do not contain access_token');
    return null;
  }

  setToken(res);
  callbackListeners[AUTH_EVENTS_TYPES.REFRESH_TOKEN]([res, err]);

  return res;
};

/**
 * Renew access, id and refresh tokens for your user.
 *
 * @returns {TokenResponse | null} response retrieved on success or null on failure
 * @event AUTH_EVENTS_TYPES.RENEW_SESSION is fired after successfully retrieved token
 * @example
 *
 * const token = await renewSession() // if request is successful it will return object if not it will return null
 */
export const renewSession = async (refreshToken: string): Promise<TokenResponse | null> => {
  if (!isConfigured('renewSession')) return null;

  refreshToken?.length && setStorageValue(storageKey, JSON.stringify({ refresh_token: refreshToken }), storageInstance);

  const tokenData = await refreshTokensSet();

  tokenData && callbackListeners[AUTH_EVENTS_TYPES.RENEW_SESSION](tokenData);

  return tokenData;
};

/**
 * Get the saved token into storage, decode it to json object and return it
 *
 * @returns {JWT | null} decoded json object which is stored inside the token
 * @example
 *
 * decodeToken() // { user: 'user', expiresIn: 12333 }
 */
export const decodeToken = (): JWT | null => {
  try {
    const accessToken = getToken()?.access_token;
    if (!accessToken) return null;

    return JSON.parse(decodeBase64(accessToken.split('.')?.[1] ?? '')) as JWT;
  } catch (err) {
    softLogout();
    console.error(err);
    return null;
  }
};

/**
 * Return true if there is saved token into storage and it is still valid, there is threshold of 1 minute for validations
 *
 * @returns {boolean} true if the token has more than 1 minute to expire
 * @example
 *
 * isTokenValid()
 */
export const isTokenValid = (threshold: number): boolean => {
  const token = decodeToken();
  return !!token && token?.exp * 1000 - (isNumber(threshold) ? threshold : 0) > Date.now();
};

/**
 * When new token is set there should be init new setTimeout to execute refreshToken when the expiration of the current one comes
 *
 * @example
 *
 * registerRefreshTokenListener()
 */
const registerRefreshTokenListener = (): void => {
  unregisterTokenListener();
  const tokenExp = decodeToken()?.exp;
  if (!tokenExp) return;

  const expirationTime = tokenExp * 1000 - Date.now() - 30000;

  refreshTokenListener = setTimeout(() => {
    refreshTokensSet();
  }, expirationTime);
};

/**
 * Remove setTimeout which execute refreshTokensSet when the expiration of the current one comes
 *
 * @example
 *
 * unregisterTokenListener()
 */
export const unregisterTokenListener = (): void => {
  refreshTokenListener && clearTimeout(refreshTokenListener);
};

/**
 * Logout from your application only, without logging out the use from the identity\
 * This method will erase the storage key which keeps the token
 * It is implemented here due to import dependency cycle - identity.js and token.js
 *
 * @event AUTH_EVENTS_TYPES.LOGOUT is fired before the return clause
 * @see logout
 * @example
 *
 * softLogout() -> will empty all items from local storage
 */
export const softLogout = (): void => {
  removeStorageValue(storageKey, storageInstance);
  unregisterTokenListener();
  callbackListeners[AUTH_EVENTS_TYPES.LOGOUT]();
};
