import { config } from '../config';
import { Geolocation, Continent, Country } from '../interfaces';
import { getContinentsResponse } from './models';

const locationsUrl = () => `${config.dealshakerPortalApiDomain}/api/Location`;
const managersUrl = () => `${config.dealshakerAdminApiDomain}/api/Managers`;

export const getGeoLocation = () => config.requestInstance.get<Geolocation>(`${locationsUrl()}/Get`);

export const getContinents = async (): Promise<[Continent[] | null, any]> => {
  const [res, err] = await config.requestInstance.get<Continent[]>(`${locationsUrl()}/All`);
  return [res && getContinentsResponse(res), err];
};

export const getManagerCountries = () => config.requestInstance.get<Country[]>(`${managersUrl()}/MyCountries`);
