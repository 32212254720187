import { camelCase } from '@veraio/core';
import { invert } from 'lodash-es';

export const deliveryMethod = {
  selfOrganizedShipping: 1,
  redeemAtLocation: 2,
  onlineService: 3,
};

export const deliveryMethodInverse = invert(deliveryMethod);

export const deliveryMethodSelect = {
  [deliveryMethod.selfOrganizedShipping]: {
    label: 'selfOrganizedShipping',
    icon: 'icon-Delivery-Type-Self-organized',
  },
  [deliveryMethod.redeemAtLocation]: {
    label: 'redeemAtLocation',
    icon: 'icon-Delivery-Type-Redeem-at-location',
  },
  [deliveryMethod.onlineService]: {
    label: 'onlineService',
    icon: 'icon-Delivery-Type-Online-Service',
  },
};

export const dealStatus = {
  pending: 1,
  approved: 2,
  declined: 3,
  expired: 4,
  closed: 5,
  soldOut: 6,
  draft: 7,
  suspended: 8,
};

export const dealStatusInverse = invert(dealStatus);

export const promoCodeType = {
  euro: 1,
  percent: 2,
};

export const promoCodeTypeInverse = invert(promoCodeType);

export const promoCodeTypeSelect = Object.entries(promoCodeType).map(([key, value]) => ({
  label: camelCase(`create-promo-code-${key}`),
  value,
}));

export const dealsSliderType = {
  dealsOfTheDay: 1,
  featured: 2,
};

export const dealsSliderTypeInverse = invert(dealsSliderType);

export const dealWeightType = {
  gr: 1,
  kg: 2,
};

export const dealWeightTypeInverse = invert(dealWeightType);

export const dealWeightTypeSelect = [
  {
    label: 'kg',
    value: dealWeightType.kg,
  },
  {
    label: 'gr',
    value: dealWeightType.gr,
  },
];

export const couponStatus = {
  new: 1,
  notRedeemed: 2,
  redeemed: 3,
  cancelled: 4,
  refunded: 5,
  expired: 6,
  cryptoPaid: 7,
};

export const couponStatusInverse = invert(couponStatus);

export const refusedReviewsStatus = {
  draft: dealStatus.draft,
  declined: dealStatus.declined,
  expired: dealStatus.expired,
};

export const refusedReviewsStatusInverse = invert(refusedReviewsStatus);
