import { Currency } from '../interfaces';

export const DEFAULT_FIAT_CURRENCY: Currency = {
  name: 'Euro',
  symbol: '€',
  code: 'EUR',
  rates: {},
};

/**
 * Enum for all crypto currencies which are supported into dealshaker\
 * Soon this need to be removed in flavour of strank configuration
 */
export const CRYPTO_CURRENCIES = {
  ONE: {
    name: 'ONE',
    symbol: 'ONE',
    code: 'ONE',
  },
  OESP: {
    name: 'OESP',
    symbol: 'OESP',
    code: 'OESP',
  },
} as const;

export type CRYPTO_CURRENCY = typeof CRYPTO_CURRENCIES;

export const DEFAULT_ONE_RATE = 42.5;
export const DEFAULT_CRYPTO_CURRENCY: Currency = {
  ...CRYPTO_CURRENCIES.ONE,
  rates: { [DEFAULT_FIAT_CURRENCY.code]: DEFAULT_ONE_RATE },
};
