import { useStore, createStore, SelectorFunction } from '@veraio/core';
import { isConfigured } from '../config';
import {
  addNewAddress,
  setDefaultAddress,
  updateDeliveryInstructions,
  updateDeliveryAddress,
  deleteDeliveryAddress,
} from '../services';
import { UserAddress } from '../interfaces';

const addressesStore = createStore<UserAddress[]>([]);

/**
 * Initialize user addresses store
 *
 * @param addresses an array of objects with user addresses
 * @example
 *
 * initAddresses([{ id: 15, street: 'Hin Dzao', ... }, ...]) // address will be added to store
 */
export const initAddresses = (addresses: UserAddress[]): void => {
  if (!isConfigured('initAddresses')) return;

  return addressesStore.setState(addresses ?? []);
};

/**
 * Add user address, if there is error returned from API it will be returned
 *
 * @param newAddress an object for new user address
 * @returns {Promise<any>} error from api call if there is such error
 * @example
 *
 * addAddress({ street: 'Hin Dzao', ... }) // address will be added and new address from response will be added to store
 */
export const addAddress = async (newAddress: UserAddress): Promise<any> => {
  if (!isConfigured('addAddress')) return;

  const [res, err] = await addNewAddress(newAddress);
  res && addressesStore.setState((prev) => prev.concat(res));
  return err;
};

/**
 * Edit user address, if there is error returned from API it will be returned
 *
 * @param newAddress an object with new data about user address
 * @returns {Promise<any>} error from api call if there is such error
 * @example
 *
 * updateAddress({ id: 15, street: 'Hin Dzao', ... }) // address with id 15 will be updated
 */
export const updateAddress = async (newAddress: UserAddress): Promise<any> => {
  if (!isConfigured('updateAddress')) return;

  const [res, err] = await updateDeliveryAddress(newAddress);
  !err && addressesStore.setState((prev) => prev.map((el) => (el.id === res?.id ? res : el)));
  return err;
};

/**
 * Edit user address to make is default for the user, if there is error returned from API it will be returned
 *
 * @param id id number of the address which you want to update
 * @returns {Promise<any>} error from api call if there is such error
 * @example
 *
 * updateDefaultAddress(15) // address with id 15 will be set as default
 */
export const updateDefaultAddress = async (id: number): Promise<any> => {
  if (!isConfigured('updateDefaultAddress')) return;

  const [, err] = await setDefaultAddress(id);
  !err && addressesStore.setState((prev) => prev.map((el) => ({ ...el, isDefault: el.id === id })));
  return err;
};

/**
 * Edit user address delivery instructions by passing an id of that address and string for delivery instructions\
 * If there is error returned from API it will be returned
 *
 * @param id id number of the address which you want to update
 * @param deliveryInstructions string for new delivery instructions
 * @returns {Promise<any>} error from api call if there is such error
 * @example
 *
 * updateAddressInstructions(15, 'Deliver to front door') // address with id 15 will be edited, if there is no such address no change will be applied
 */
export const updateAddressInstructions = async (id: number, deliveryInstructions: string): Promise<any> => {
  if (!isConfigured('updateAddressInstructions')) return;

  const [, err] = await updateDeliveryInstructions(id, deliveryInstructions);
  !err && addressesStore.setState((prev) => prev.map((el) => (el.id === id ? { ...el, deliveryInstructions } : el)));
  return err;
};

/**
 * Delete user address by passing an id of that address, if there is error returned from API it will be returned
 *
 * @param id id number of the address which you want to delete
 * @returns {Promise<any>} error from api call if there is such error
 * @example
 *
 * deleteAddress(15) // address with id 15 will be deleted if there is any, if there is no such address no change will be applied
 */
export const deleteAddress = async (id: number): Promise<any> => {
  if (!isConfigured('deleteAddress')) return;

  const [, err] = await deleteDeliveryAddress(id);
  !err && addressesStore.setState((prev) => prev.filter((el) => el.id !== id));
  return err;
};

/**
 * React hook to use the store inside components with selector method for subscribe to changes for part of the state
 *
 * @param callback function that accept the hole state as argument and return a part of that state which need to be changed for a re-render
 * @returns {UserAddress[]} array of all user addresses
 * @example
 *
 * useAddresses(state => state.address) // this component will re-render only when there is change inside address
 */
export const useAddresses = (callback: SelectorFunction<UserAddress[]>): UserAddress[] | null => {
  if (!isConfigured('useAddresses')) return null;

  return useStore(addressesStore, callback);
};
