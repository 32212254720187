import { PropertyPath, AnyFunction } from '../enums';

export const argsTag = '[object Arguments]',
  arrayTag = '[object Array]',
  asyncTag = '[object AsyncFunction]',
  boolTag = '[object Boolean]',
  dateTag = '[object Date]',
  domExcTag = '[object DOMException]',
  errorTag = '[object Error]',
  funcTag = '[object Function]',
  genTag = '[object GeneratorFunction]',
  mapTag = '[object Map]',
  nullTag = '[object Null]',
  numberTag = '[object Number]',
  objectTag = '[object Object]',
  promiseTag = '[object Promise]',
  proxyTag = '[object Proxy]',
  regexpTag = '[object RegExp]',
  setTag = '[object Set]',
  stringTag = '[object String]',
  symbolTag = '[object Symbol]',
  undefinedTag = '[object Undefined]',
  weakMapTag = '[object WeakMap]',
  weakSetTag = '[object WeakSet]';

export const arrayBufferTag = '[object ArrayBuffer]',
  dataViewTag = '[object DataView]',
  float32Tag = '[object Float32Array]',
  float64Tag = '[object Float64Array]',
  int16Tag = '[object Int16Array]',
  int32Tag = '[object Int32Array]',
  int8Tag = '[object Int8Array]',
  uint16Tag = '[object Uint16Array]',
  uint32Tag = '[object Uint32Array]',
  uint8ClampedTag = '[object Uint8ClampedArray]',
  uint8Tag = '[object Uint8Array]';

/**
 * Check if the provided argument is string or number
 *
 * @param val string or number
 * @returns boolean
 *
 * @example
 * isStrNum(4) // true
 * isStrNum('string') // true
 * isStrNum(null) // false
 * isStrNum({}) // false
 */
export const isStrNum = (val: string | number): boolean => isString(val) || isNumber(val);

/**
 * Checks if provided argument is stringified json or not
 *
 * @param text stringified json
 * @returns boolean
 *
 * @example
 * isJsonString('{a: 'hello', b: 'world'}') // true
 * isJsonString('[1, 3, 'string', {}]') // true
 * isJsonString(null) // false
 * isJsonString(2) // false
 */
export const isJsonString = (text: string): boolean => {
  if (typeof text !== 'string') return false;

  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Parse stringified json as json object
 *
 * @param string stringified json
 * @param defaultValue default value if parse can not be done
 * @returns json object
 *
 * @example
 * parseJson('{a: 'hello', b: 'world'}') // {a: 'hello', b: 'world'}
 * parseJson('[1, 3, 'string', {}]') // [1, 3, 'string', {}]
 * parseJson(null, {}) // {}
 * parseJson(2) // undefined
 */
export const parseJson = (string: string, defaultValue: any = null): any => {
  try {
    const parsed = JSON.parse(string);
    return parsed;
  } catch (e) {
    console.error('Can not parse JSON value\n', e);
    return defaultValue;
  }
};

/**
 * Parse stringified json as json object
 *
 * @param string stringified json
 * @param defaultValue default value if parse can not be done
 * @returns json object
 *
 * @example
 * stringifyJson({a: 'hello', b: 'world'}) // '{a: 'hello', b: 'world'}'
 * stringifyJson([1, 3, 'string', {}]) // '[1, 3, 'string', {}]'
 * stringifyJson(null) // 'null'
 * stringifyJson(2) // '2'
 */
export const stringifyJson = (json: any): string => JSON.stringify(json);

/**
 * Check if 2 arguments are equal by value not by reference as the default behavior of JS
 *
 * @param a any
 * @param b any
 * @returns boolean
 *
 * @example
 * deepEqual({a: 'hello'}, {b: 'world'}) // false
 * deepEqual([1, 2], [2, 3]) // false
 * deepEqual({a: 'hello'}, {a: 'hello'}) // true
 * deepEqual([1, 2], [1, 2]) // true
 * deepEqual(1, 1) // true
 * deepEqual('string', 'string') // true
 */
export const deepEqual = (a: any, b: any): boolean => {
  if (a === b) return true;

  if (typeof a === 'function' && b.toString) return a.toString() === b.toString();

  if (a && b && typeof a === 'object' && typeof b === 'object') {
    if (a.constructor !== b.constructor) return false;

    if (Array.isArray(a)) {
      if (a.length !== b.length) return false;
      for (let i = a.length - 1; i >= 0; i--) if (!deepEqual(a[i], b[i])) return false;
      return true;
    }

    if (a instanceof Map && b instanceof Map) {
      if (a.size !== b.size) return false;
      for (const i of a.entries()) if (!b.has(i[0])) return false;
      for (const i of a.entries()) if (!deepEqual(i[1], b.get(i[0]))) return false;
      return true;
    }

    if (a instanceof Set && b instanceof Set) {
      if (a.size !== b.size) return false;
      for (const i of a.entries()) if (!b.has(i[0])) return false;
      return true;
    }

    if (a.constructor === RegExp && b.constructor === RegExp) return a.source === b.source && a.flags === b.flags;
    if (a.valueOf !== Object.prototype.valueOf || b.valueOf !== Object.prototype.valueOf)
      return a.valueOf() === b.valueOf();
    if (a.toString !== Object.prototype.toString || b.toString !== Object.prototype.toString)
      return a.toString() === b.toString();

    const keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) return false;

    for (let i = keys.length - 1; i >= 0; i--)
      if (!Object.prototype.hasOwnProperty.call(b, keys[i] as string)) return false;

    for (let i = keys.length - 1; i >= 0; i--) {
      const key = keys[i] as string;

      if (key === '_owner' && a.$$typeof) {
        // React-specific: avoid traversing React elements' _owner.
        //  _owner contains circular references
        // and is not needed when comparing the actual elements (and not their owners)
        continue;
      }

      if (!deepEqual(a[key], b[key])) return false;
    }

    return true;
  }

  return false;
};

/**
 * Gets the `toStringTag` of `value`.
 *
 * @param {*} value The value to query.
 * @returns {string} Returns the `toStringTag`.
 *
 * @example
 * getTag({a: 'hello'}) // '[object Object]'
 * getTag('asd') // '[object String]'
 */
export const getTag = (value: any): string => {
  if (value == null) return value === undefined ? undefinedTag : nullTag;

  return toString.call(value);
};

/**
 * Checks if `value` is object-like. A value is object-like if it's not `null` and has a `typeof` result of "object".
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is object-like, else `false`.
 * @example
 *
 * isObjectLike({}) // true
 * isObjectLike([1, 2, 3]) // true
 * isObjectLike(Function) // false
 * isObjectLike(null) // false
 */
export const isObjectLike = (value: any): value is object => typeof value === 'object' && value !== null;

/**
 * Checks if `value` is a plain object, that is, an object created by the\
 * `Object` constructor or one with a `[[Prototype]]` of `null`.
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a plain object, else `false`.
 * @example
 *
 * function Foo() {
 *   this.a = 1
 * }
 *
 * isPlainObject(new Foo) // false
 * isPlainObject([1, 2, 3]) // false
 * isPlainObject({ 'x': 0, 'y': 0 }) // true
 * isPlainObject(Object.create(null)) // true
 */
export const isPlainObject = (value: any): value is object => {
  if (!isObjectLike(value) || getTag(value) != objectTag) return false;

  if (Object.getPrototypeOf(value) === null) return true;

  let proto = value;
  while (Object.getPrototypeOf(proto) !== null) proto = Object.getPrototypeOf(proto);

  return Object.getPrototypeOf(value) === proto;
};

/**
 * Checks if `value` is classified as a `Array`.
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a array, else `false`.
 * @example
 *
 * isArray([1, 2]) // true
 * isArray({}) // false
 * isArray(Infinity) // false
 * isArray('3') // false
 */
export const isArray = (value: any): value is Array<any> => Array.isArray(value);

/**
 * Checks if `value` is classified as a `Number` primitive or object.
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a number, else `false`.
 * @example
 *
 * isNumber(3) // true
 * isNumber(Number.MIN_VALUE) // true
 * isNumber(Infinity) // true
 * isNumber('3') // false
 */
export const isNumber = (value: any): value is number =>
  (typeof value === 'number' || (isObjectLike(value) && getTag(value) == numberTag)) &&
  !Number.isNaN(value) &&
  value !== Number.POSITIVE_INFINITY &&
  value !== Number.NEGATIVE_INFINITY;

/**
 * Checks if `value` is classified as a `String` primitive or object.
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a string, else `false`.
 * @example
 *
 * isString('abc') // true
 * isString(1) // false
 */
export const isString = (value: any): value is string => {
  const type = typeof value;
  return (
    type === 'string' || (type === 'object' && value != null && !Array.isArray(value) && getTag(value) == stringTag)
  );
};

/**
 * Checks if `value` is classified as a `RegExp`.
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a regexp, else `false`.
 * @example
 *
 * isRegExp(123) // false
 * isRegExp('qwe') // false
 * isRegExp(new RegExp('')) // true
 * isRegExp(/\d+/g) // true
 */
export const isRegExp = (value: any): value is RegExp => isObjectLike(value) && getTag(value) == regexpTag;

/**
 * Checks if `value` is undefined.
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a undefined, else `false`.
 * @example
 *
 * isUndefined(123) // false
 * isUndefined('qwe') // false
 * isUndefined(null) // false
 * isUndefined(undefined) // true
 */
export const isUndefined = (value: any): value is undefined => value === undefined;

/**
 * Checks if `value` is classified as a `Function` object.
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a function, else `false`.
 * @example
 *
 * isFunction(class Any{}) // true
 * isFunction(() => {}) // true
 * isFunction(async () => {}) // true
 * isFunction(function * Any() {}) // true
 * isFunction(Math.round) // true
 * isFunction(/abc/) // false
 */
export const isFunction = (value: any): value is AnyFunction => typeof value === 'function';

/**
 * Checks if `value` is `null` or `undefined`.
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is nullish, else `false`.
 * @example
 *
 * isNil(null) // true
 * isNil(void 0) // true
 * isNil(NaN) // false
 */
export const isNil = (value: any): value is null => value === null || value === undefined;

/**
 * Check if value has properties/elements/characters
 *
 * @param {any} value object, array or string to be checked
 * @returns {boolean} true if the value has not properties/elements/characters
 * @example
 *
 * isEmpty([]) // true
 * isEmpty({}) // true
 * isEmpty('') // true
 * isEmpty(null) // true
 * isEmpty(12) // true
 * isEmpty([1]) // false
 * isEmpty({a: 1}) // false
 * isEmpty('a') // false
 */
export const isEmpty = (value: any): boolean => {
  if (isArray(value) || isString(value)) return !value.length;
  if (isPlainObject(value)) return !Object.keys(value).length;

  return true;
};

/**
 * Pass a single string or array of strings and return array of strings.\
 * If you pass something different from string and array of strings it will return null.
 *
 * @param {string | string[]} args string or array of strings
 * @returns {string[] | null} array of strings or null
 * @example
 *
 * multiplePathArgs('asd') // ['asd']
 * multiplePathArgs(['asd', 'qwe']) // ['asd', 'qwe']
 * multiplePathArgs(123) // null
 * multiplePathArgs([]) // null
 */
export const multiplePathArgs = (args: PropertyPath): string[] | null =>
  isArray(args) && args.every(isString) && args.length ? args : isString(args) ? [args] : null;
