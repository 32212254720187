import { isString, isPlainObject, isFunction, isArray, multiplePathArgs, isNil, isEmpty, deepEqual } from './lodash';
import { PropertyPath, ObjectIterator, List, ValueIteratee, PropertyName, Dictionary } from '../enums';

const reEscapeChar = /\\(\\)?/g;
const rePropName = RegExp(
  // Match anything that isn't a dot or bracket.
  '[^.[\\]]+' +
    '|' +
    // Or match property names within brackets.
    '\\[(?:' +
    // Match a non-string expression.
    '([^"\'][^[]*)' +
    '|' +
    // Or match strings (supports escaping characters).
    '(["\'])((?:(?!\\2)[^\\\\]|\\\\.)*?)\\2' +
    ')\\]' +
    '|' +
    // Or match "" as the space between consecutive dots or empty brackets.
    '(?=(?:\\.|\\[\\])(?:\\.|\\[\\]|$))',
  'g',
);

/**
 * Converts `string` to a property path array.
 *
 * @param {string} string The string to convert.
 * @returns {Array} Returns the property path array and types array.
 * @example
 *
 * stringToObjectPath('a.b.c') // [['a', 'b', 'c'], ['object', 'object', 'object']]
 * stringToObjectPath('a[1]') // [['a', '1'], ['object', 'array']]
 */
export const stringToObjectPath = (string: string): [string[], string[]] => {
  const result: string[] = [];
  const types: string[] = [];
  if (!isString(string) || !string.length) return [result, types];

  string.replace(rePropName, (match, expression, quote, subString) => {
    let key = match;

    if (quote) key = subString.replace(reEscapeChar, '$1');
    else if (expression) key = expression.trim();
    types.push(/\[(?:0|[1-9]\d*)\]/.test(match) ? 'array' : 'object');
    result.push(key);

    return match;
  });

  return [result, types];
};

/**
 * Create new object composed from key-value `entries` array.
 *
 * @param {Array} entries The key-value entries.
 * @returns {Object} Returns the new object.
 * @example
 *
 * fromEntries([['a', 1], ['b', 2]]) // { 'a': 1, 'b': 2 }
 */
export const fromEntries = <T>(entries: Array<[PropertyName, T]>): Dictionary<T> => {
  try {
    return Object.fromEntries(entries);
  } catch {
    return {};
  }
};

/**
 * Create key-value `entries` array composed from object.
 *
 * @param {Object} object The object from which entries will be created.
 * @returns {Array} Returns the new array of entries.
 * @example
 *
 * toEntries({ 'a': 1, 'b': 2 }) // [['a', 1], ['b', 2]]
 */
export const toEntries = (object: object): [string, any][] => {
  try {
    return Object.entries(object);
  } catch {
    return [];
  }
};

/**
 * Make a copy of any structure with deep copy of all values
 *
 * @param {any} value structure which will be deeply copied
 * @returns {any} copy of the input structure
 * @example
 *
 * copy({ a: { b: 1 } }) // { a: { b: 1 } }
 */
export const copy = (value: any): any => {
  const copyArray = (arr: any[], callback: (cur: any) => any) => {
    const keys = Object.keys(arr);
    const a2 = new Array(keys.length);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i] as unknown as number;
      const cur = arr[key];
      if (typeof cur !== 'object' || isNil(cur)) a2[key] = cur;
      else if (cur instanceof Date) a2[key] = new Date(cur);
      else a2[key] = callback(cur);
    }
    return a2;
  };

  const copyStructure = (structure: any) => {
    if (typeof structure !== 'object' || isNil(structure)) return structure;
    if (structure instanceof Date) return new Date(structure);
    if (Array.isArray(structure)) return copyArray(structure, copyStructure);
    if (structure instanceof Map) return new Map(copyArray(Array.from(structure), copyStructure));
    if (structure instanceof Set) return new Set(copyArray(Array.from(structure), copyStructure));
    const structureData: { [key: string]: any } = {};
    for (const key in structure) {
      const cur = structure[key];
      if (typeof cur !== 'object' || isNil(cur)) structureData[key] = cur;
      else if (cur instanceof Date) structureData[key] = new Date(cur);
      else if (cur instanceof Map) structureData[key] = new Map(copyArray(Array.from(cur), copyStructure));
      else if (cur instanceof Set) structureData[key] = new Set(copyArray(Array.from(cur), copyStructure));
      else structureData[key] = copyStructure(cur);
    }
    return structureData;
  };

  return copyStructure(value);
};

/**
 * The opposite of `mapValue` this method creates an object with the\
 * same values as `object` and keys generated by running each own enumerable\
 * string keyed property of `object` thru `iteratee`. The iteratee is invoked
 * with three arguments: (value, key, object).
 *
 * @param {Object} object The object to iterate over.
 * @param {Function} iteratee The function invoked per iteration.
 * @returns {Object} Returns the new mapped object.
 * @see mapValue
 * @example
 *
 * mapKey({ 'a': 1, 'b': 2 }, (value, key, object) => key + value) // { 'a1': 1, 'b2': 2 }
 */
export const mapKeys = <T extends object>(object: T, iteratee?: ObjectIterator<T, string>): Dictionary<T> =>
  isPlainObject(object) && isFunction(iteratee)
    ? fromEntries(toEntries(object).map((el) => [iteratee(el[1], el[0], object), el[1]]))
    : {};

/**
 * Creates an object with the same keys as `object` and values generated\
 * by running each own enumerable string keyed property of `object` thru\
 * `iteratee`. The iteratee is invoked with three arguments:
 * (value, key, object).
 *
 * @param {Object} object The object to iterate over.
 * @param {Function} iteratee The function invoked per iteration.
 * @returns {Object} Returns the new mapped object.
 * @see mapKeys
 * @example
 *
 * const users = {
 *   'fred':    { 'user': 'fred',    'age': 40 },
 *   'pebbles': { 'user': 'pebbles', 'age': 1 }
 * }
 *
 * mapValue(users, ({ age }) => age) // { 'fred': 40, 'pebbles': 1 } (iteration order is not guaranteed)
 */
export const mapValues = <T extends object, Result>(
  object: T,
  iteratee: ObjectIterator<T, Result>,
): Dictionary<Result> =>
  isPlainObject(object) && isFunction(iteratee)
    ? fromEntries(toEntries(object).map((el) => [el[0], iteratee(el[1], el[0], object)]))
    : {};

/**
 * The base implementation of `set` to change value inside array or object with specific path.
 *
 * @param {object | any[]} object The object to modify.
 * @param {string} path The path of the property to set.
 * @param {any} value The value to set.
 * @returns {object | any[]} Returns object/array with changed value or null if input is invalid.
 */
export const setProperty = <T extends object>(structure: T, path: PropertyPath, value: any): T => {
  if ((!isPlainObject(structure) && !isArray(structure)) || !isString(path)) return structure;

  const structureCopy: any = copy(structure);
  let temp: any = structureCopy;
  const [objectPath, types] = stringToObjectPath(path);

  objectPath.forEach((key, index) => {
    if (!Object.hasOwn(temp, key)) temp[key] = types[index + 1] === 'array' ? [] : {};
    if (index === objectPath.length - 1) temp[key] = value;
    else temp = temp[key];
  });

  return structureCopy;
};

/**
 * The base implementation of `get` to retrieve value inside array or object with specific path.
 *
 * @param {object | any[]} object The object to get value from.
 * @param {string} path The path of the property to get.
 * @returns {any} Returns the value of this path or undefined if the path is invalid.
 */
export const getProperty = <T extends object>(object: T, path: PropertyPath): T[keyof T] | undefined => {
  if ((!isPlainObject(object) && !isArray(object)) || !isString(path)) return;

  let objectValue: any = object;
  stringToObjectPath(path)[0].forEach((key) => {
    objectValue = Object.hasOwn(objectValue ?? {}, key) ? objectValue[key] : undefined;
  });

  return objectValue;
};

/**
 * The base implementation of `delete` to remove property inside array or object with specific path.
 *
 * @param {object | any[]} object The object to delete property from.
 * @param {string} path The path of the property to delete.
 * @returns {object | any[]} Returns the value of this path or null if the path is invalid.
 */
export const deleteProperty = <T extends object>(structure: T, path: PropertyPath): Partial<T> => {
  if ((!isPlainObject(structure) && !isArray(structure)) || !isString(path)) return {};

  const structureCopy: any = copy(structure);
  let temp: any = structureCopy;
  const [objectPath] = stringToObjectPath(path);

  objectPath.forEach((key, index) => {
    if (!Object.hasOwn(temp, key)) return structureCopy;

    if (index === objectPath.length - 1) isArray(temp) ? temp.splice(Number(key), 1) : delete temp[key];
    else temp = temp[key];
  });

  return structureCopy;
};

/**
 * The base implementation of `delete` to remove property inside array or object with specific path.\
 * The difference with deleteProperty is that it will delete every empty parent to top
 *
 * @param {object | any[]} object The object to delete property from.
 * @param {string} path The path of the property to delete.
 * @returns {object | any[]} Returns the value of this path or null if the path is invalid.
 */
export const deleteDeepProperty = <T extends object>(structure: T, path: PropertyPath): Partial<T> => {
  if ((!isPlainObject(structure) && !isArray(structure)) || !isString(path)) return {};

  const structureCopy: any = copy(structure);
  let temp: any = structureCopy;
  const [objectPath] = stringToObjectPath(path);
  const returnObjectPath = (
    objectPath.slice(0, objectPath.length - 1).reduce((acc: any, _, ind, arr) => {
      acc.push(arr.slice(0, ind + 1));
      return acc;
    }, []) as string[][]
  ).reverse();

  objectPath.forEach((key, index) => {
    if (!Object.hasOwn(temp, key)) return structureCopy;

    if (index === objectPath.length - 1) {
      isArray(temp) ? temp.splice(Number(key), 1) : delete temp[key];
      returnObjectPath.forEach((parentPath) => {
        temp = structureCopy;
        temp = parentPath.forEach((path, ind, arr) => {
          if (ind === arr.length - 1 && isEmpty(temp[path]))
            isArray(temp) ? temp.splice(Number(path), 1) : delete temp[path];
          else temp = temp[path];
        });
      });
    } else temp = temp[key];
  });

  return structureCopy;
};

/**
 * Remove properties form object param if it is plain object\
 * If you do not pass correct params it will return null
 *
 * @param {object} object plain object which keys can be omitted
 * @param {string | string[]} keys string or array of strings for keys to be omitted
 * @returns {object | null} new object with left keys or null for invalid input
 * @example
 *
 * omit({a: 1, b: 2}, 'a') // {b: 2}
 * omit({a: 1, b: 2}, ['a', 'b']) // {}
 * omit({a: 1, b: 2}, 'c') // {a: 1, b: 2}
 * omit({a: 1, b: 2}, []) // {a: 1, b: 2}
 * omit([], 'a') // null
 * omit('string', 'a') // null
 * omit({}, 123) // null
 * omit({}) // null
 */
export const omit = <T extends object>(object: T, keys: PropertyPath): Partial<T> => {
  const params = multiplePathArgs(keys);
  if (isNil(params) || !isPlainObject(object)) return {};

  let objectCopy = copy(object);
  params.forEach((path) => {
    objectCopy = deleteProperty(objectCopy, path);
  });

  return objectCopy;
};

/**
 * Extract properties form object param if it is plain object\
 * If you do not pass correct params it will return null
 *
 * @param {object} object plain object which keys can be picked
 * @param {string | string[]} keys string or array of strings for keys to be picked
 * @returns {object | null} new object with picked keys or null for invalid input
 * @example
 *
 * pick({a: 1, b: 2}, 'a') // {a: 1}
 * pick({a: 1, b: 2}, ['a', 'b']) // {a: 1, b: 2}
 * pick({a: 1, b: 2}, 'c') // {}
 * pick({a: 1, b: 2}, []) // {}
 * pick([], 'a') // null
 * pick('string', 'a') // null
 * pick({}, 123) // null
 * pick({}) // null
 */
export const pick = <T extends object>(object: T, keys: PropertyPath): Partial<T> => {
  const params = multiplePathArgs(keys);

  if (isNil(params) || !isPlainObject(object)) return {};

  return params.reduce((acc, path) => {
    const objValue = getProperty(object, path);
    return objValue !== undefined ? (setProperty(acc, path, objValue) as object) : acc;
  }, {});
};

/**
 * Filter array of elements by uniqueness, if element is not unique it is dropped
 *
 * @param {any[]} value An array of element
 * @returns {any[]} new array with only unique elements
 * @example
 *
 * uniq([1,2,3,1,2,3]) // [1,2,3]
 * uniq([1,2]) // [1,2]
 * uniq({}) // []
 * uniq('string') // []
 */
export const uniq = <T>(value: List<T>): T[] => {
  if (!isArray(value)) return [];

  return value.reduce((acc, el) => {
    if (!acc.some((item: any) => deepEqual(el, item))) acc.push(el);
    return acc;
  }, []);
};

/**
 * Filter array of elements by uniqueness, if element is not unique it is dropped
 *
 * @param {any[]} value An array of element
 * @returns {any[]} new array with only unique elements
 * @example
 *
 * uniq([1,2,3,1,2,3]) // [1,2,3]
 * uniq([1,2]) // [1,2]
 * uniq({}) // []
 * uniq('string') // []
 */
export const uniqBy = <T>(value: List<T>, iterator: ValueIteratee<T>): any[] => {
  if (!isArray(value) || !(isFunction(iterator) || (isString(iterator) && iterator.length))) return [];

  return value.filter((el, ind, arr) => {
    const matchedIndex = value.findIndex((item) =>
      isFunction(iterator)
        ? iterator(item, ind, arr)
        : deepEqual(getProperty(item, iterator), getProperty(el, iterator)),
    );
    return matchedIndex === -1 || matchedIndex === ind;
  });
};
