import { deepEqual, isFunction } from '../utils';

export type Store<State> = {
  setState: (newState: State | ((prev: State) => State)) => void;
  getState: () => State;
  subscribe: (listener: (nextState: State, prevState: State) => void) => void;
  destroy: () => void;
};

/**
 * Create a store which is similar to zustand, redux, jotai and so on\
 * It accepts initial value and creates store which is just a regular javascript let variable\
 * It hav interface for reactions - get and set and do not expose the state directly\
 * You can operate with this state only through the interface for set and get, setState accepts new value or function\
 * If you pass a function to setState it will be called with current state value and what it returns will be new state value\
 * It expose subscribe method which accept a method which will be fired on every state change\
 * The subscribe listener will be called with 2 arguments - prev and new value\
 * It will return method which cal be called to destroy the listener\
 * You can destroy the state and its listeners with destroy method
 *
 * @param {any} stateInitialValue variable of any type which will be used to initialize the state
 * @returns {Store} object with setState, getState, subscribe and destroy properties
 * @see Store
 * @example
 *
 * const userStore = createStore({ firstName: 'John', lastName: 'Doe' });
 * userStore.getState(); // { firstName: 'John', lastName: 'Doe' }
 * const listener = (prevState, nextState) => console.log(prevState, nextState);
 * const destroyListener = userStore.subscribe(listener);
 * userStore.setState({ firstName: 'Will', lastName: 'Begins' }); // listener function will be fired
 * userStore.getState(); // { firstName: 'Will', lastName: 'Begins' }
 * destroyListener();
 * userStore.setState({ firstName: 'John', lastName: 'Begins' }); // listener function will NOT be fired, because it is destroyed
 * userStore.getState(); // { firstName: 'John', lastName: 'Begins' }
 */
export const createStore = <State>(stateInitialValue: State): Store<State> => {
  let state: State;
  const listeners = new Set();

  const setState = (newState: State | ((prev: State) => State)) => {
    const nextState = isFunction(newState) ? newState(state) : newState;
    if (!deepEqual(nextState, state)) {
      const oldState = { state };
      state = nextState;
      listeners.forEach((listener) => isFunction(listener) && listener(nextState, oldState.state));
    }
  };

  const getState = () => state;

  const subscribe = (listener: (prevState: State, nextState: State) => void) => {
    listeners.add(listener);
    // Unsubscribe
    return () => listeners.delete(listener);
  };

  const destroy = () => listeners.clear();
  const api = { setState, getState, subscribe, destroy };
  state = isFunction(stateInitialValue) ? stateInitialValue(api) : stateInitialValue;

  return api;
};
