/* eslint-disable camelcase */
import qs from 'query-string';
import { pick } from '@veraio/core';
import {
  CreateUser,
  CreateUserAtPlace,
  CreateUserResponse,
  TokenResponse,
  LoginUserWithPassword,
  OneLifeTokenResponse,
} from './interfaces';
import {
  requestInstance,
  callbackListeners,
  oneLifeApiDomain,
  identityConfig,
  isConfigured,
  xFromHeader,
} from './config';
import { AUTH_EVENTS_TYPES } from './enums';
import { getToken, setToken } from './token';

/**
 * Register inside identity through their web interface\
 * Both identities have internal register flows which can handle register new user
 *
 * @returns {string} url to the internal identity register page
 * @event AUTH_EVENTS_TYPES.REGISTER_REQUEST is fired before the return clause
 * @example
 *
 * window.location = register()
 * <a href={register()}>Register</a>
 */
export const register = (): string => {
  if (!isConfigured('register')) return window.location.href;

  const [registerUrl, registerParams] = identityConfig.register();
  const params = qs.stringify(registerParams as Record<string, any>);

  callbackListeners[AUTH_EVENTS_TYPES.REGISTER_REQUEST]();

  return `${registerUrl}?${params}`;
};

/**
 * Register inside identity through your own web interface, without any redirects to external pages\
 * Both identities have api register which can create new user
 *
 * @param data object for user data
 * @returns {Promise} resolved with new created user
 * @event AUTH_EVENTS_TYPES.REGISTER is fired before the return clause
 * @example
 *
 * registerOneLife({
 *   profile: {
 *    sponsorAccountId: 4022;
 *    firstName: John;
 *    lastName: Dow;
 *    country: USA;
 *    imaEnabled: true;
 *    newsletterEnabled: true;
 *    ageCompliant: true;
 *    invitationToken: null | string;
 *    preferredAccountNickname: accountName;
 *  };
 *  user: {
 *    userName: john;
 *    email: doe@gmail.com;
 *    password: Test_123!; -> must contain one upper case, one lower case, one number and one special character
 *    confirmPassword: Test_123!;
 *    roleName: Customer; -> All new members are registered as Customers
 *    phone: 0877887766;
 *  };
 * })
 */
export const registerProfile = async (data: CreateUser): Promise<[CreateUserResponse | null, any]> => {
  if (!isConfigured('registerProfile')) return new Promise((resolve) => resolve([null, new Error()]));

  const response = await requestInstance.post<CreateUserResponse>(`${oneLifeApiDomain}/users/New`, data);

  callbackListeners[AUTH_EVENTS_TYPES.REGISTER](response as [CreateUserResponse | null, any]);

  return response;
};

/**
 * Register inside identity through your own web interface, without any redirects to external pages\
 * Both identities have api register which can create new user\
 * This method is different from registerProfile because with this method after the registration the new account\
 * will be placed on particular place inside the network tree
 *
 * @param data object for user data
 * @returns {Promise} resolved with new created user
 * @event AUTH_EVENTS_TYPES.REGISTER is fired before the return clause
 * @example
 *
 * registerOneLife({
 *  account: {
 *    predefinedParentId: 3987;
 *    predefinedLeg: 'left' | 'right';
 *  };
 *  profile: {
 *    sponsorAccountId: 4022;
 *    firstName: John;
 *    lastName: Dow;
 *    country: USA;
 *    imaEnabled: true;
 *    newsletterEnabled: true;
 *    ageCompliant: true;
 *    invitationToken: null | string;
 *    preferredAccountNickname: accountName;
 *  };
 *  user: {
 *    userName: john;
 *    email: doe@gmail.com;
 *    password: Test_123!; -> must contain one upper case, one lower case, one number and one special character
 *    confirmPassword: Test_123!;
 *    roleName: Customer; -> All new members are registered as Customers
 *    phone: 0877887766;
 *  };
 * })
 */
export const registerProfileOnChosenPlace = async (
  data: CreateUserAtPlace,
): Promise<[CreateUserResponse | null, any]> => {
  if (!isConfigured('registerProfileOnChosenPlace')) return new Promise((resolve) => resolve([null, new Error()]));
  const response = await requestInstance.post<CreateUserResponse>(`${oneLifeApiDomain}/users/newassociate`, data);

  callbackListeners[AUTH_EVENTS_TYPES.REGISTER](response as [CreateUserResponse | null, any]);

  return response;
};

/**
 * Login inside identity through their web interface\
 * Both identities have support for authorization grant flow for authentication\
 * You should redirect the user to identity login page, there he will fill up login form and click login button\
 * After success login inside the identity he will be redirected to redirect_url from config which by default is /authorization-callback\
 * On this page the url will contain 3 query params - 2 of them are code and state, with the code you can call exchangeCodeForToken to retrieve token
 *
 * @param state anything that you want to pass to login callback page when user is redirected back
 * @returns {string} url to the internal login identity page
 * @event AUTH_EVENTS_TYPES.LOGIN_REQUEST is fired before the return clause
 * @see exchangeCodeForToken
 * @example
 *
 * window.location = login()
 * <a href={login()}>Login</a>
 */
export const login = (state: any): string | null => {
  if (!isConfigured('login')) return null;

  const [loginUrl, loginParams] = identityConfig.login();
  const params = qs.stringify({
    ...pick(identityConfig, ['client_id', 'redirect_uri', 'scope']),
    grant_type: 'authorization_code',
    response_type: 'code',
    prompt: 'login',
    state: JSON.stringify({ location: window.location.pathname, ...state }),
    ...loginParams,
  });

  callbackListeners[AUTH_EVENTS_TYPES.LOGIN_REQUEST]();

  return `${loginUrl}?${params}`;
};

/**
 * Login inside identity through providing username and password\
 * Both identities have support for password grant flow for authentication\
 * You should retrieve the user username and password through a login form into your application\
 * With username and password call this method which will return on success a set of tokens, on error it will perform softLogout
 *
 * @param data object with username and password properties for user credentials
 * @returns {string} url to the internal login identity page
 * @event AUTH_EVENTS_TYPES.LOGIN_WITH_PASSWORD is fired before the return clause
 * @example
 *
 * loginWithPassword({ username: 'customeruser15@yopmail.com', password: 'Customer_123' })
 */
export const loginWithPassword = async (data: LoginUserWithPassword): Promise<any> => {
  if (!isConfigured('loginWithPassword')) return null;

  const [loginUrl] = identityConfig.loginWithPassword();
  const body = {
    ...data,
    scope: identityConfig.scope,
    keyCloakClientId: identityConfig.client_id,
    stsClientId: 'OneLife',
    stsClientSecret: 'OneLifeSecret',
  };

  const [apiData, err] = await requestInstance.post<OneLifeTokenResponse>(loginUrl, body);
  const res: TokenResponse = {
    access_token: apiData?.accessToken ?? '',
    id_token: apiData?.identityToken ?? '',
    refresh_token: apiData?.refreshToken ?? '',
    expires_in: apiData?.expiresIn ?? 0,
  };

  if (!res?.access_token) {
    console.error('Unable to login, the response of get token request do not contain access_token');
    return err;
  }

  setToken(res);
  callbackListeners[AUTH_EVENTS_TYPES.LOGIN_WITH_PASSWORD]([res, err]);
  return null;
};

/**
 * After success login inside the identity he will be redirected to redirect_url from config which by default is /authorization-callback\
 * On this page the url will contain 3 query params - 2 of them are code and state, with the code you can call exchangeCodeForToken to retrieve token\
 * Pass the code query param to this method and you will receive as response the token
 *
 * @param code query param from the redirect back to your app after success login
 * @event AUTH_EVENTS_TYPES.LOGIN is fired with token as param right before the return clause after successfully retrieved token
 * @see login
 * @example
 *
 * exchangeCodeForToken(code)
 */
export const exchangeCodeForToken = async (): Promise<void> => {
  if (!isConfigured('exchangeCodeForToken')) return;

  const { code } = qs.parse(location.search);
  if (!code) return console.error('There is missing query param code into browser url');

  const [exchangeCodeUrl, exchangeCodeParams] = identityConfig.exchangeCodeForToken();
  const body = qs.stringify({
    ...pick(identityConfig, ['client_id', 'redirect_uri', 'grant_type']),
    code,
    ...exchangeCodeParams,
  });

  const [res, err] = await requestInstance.post<TokenResponse>(exchangeCodeUrl, body, xFromHeader);

  if (!res?.access_token)
    return console.error('Unable to login, the response of get token request do not contain access_token');

  setToken(res);
  callbackListeners[AUTH_EVENTS_TYPES.LOGIN]([res, err]);
};

/**
 * Logout from identity, both identities have support for logout on their side\
 * You should redirect the user to identity logout page, there he will be logged out automatically if id_token is passed, if not he should click logout button\
 * After success logout inside the identity he will be redirected to post_logout_redirect_uri from config which by default is /logout\
 * On this page you should call softLogout method which will erase the storage key which holds the token
 *
 * @returns {string} url to the internal logout identity page
 * @event AUTH_EVENTS_TYPES.LOGOUT_REQUEST is fired before the return clause
 * @see softLogout
 * @example
 *
 * window.location = logout()
 * <a href={logout()}>Logout</a>
 */
export const logout = (): string => {
  if (!isConfigured('logout')) return window.location.href;

  const idToken = getToken()?.id_token;
  const [logoutUrl, logoutParams] = identityConfig.logout();

  const params = qs.stringify({
    ...(idToken && { id_token_hint: idToken }),
    post_logout_redirect_uri: identityConfig.post_logout_redirect_uri,
    ...logoutParams,
  });

  callbackListeners[AUTH_EVENTS_TYPES.LOGOUT_REQUEST]();

  return `${logoutUrl}?${params}`;
};

/**
 * Update password inside identity through their web interface\
 * Both identities have support for update existing user password\
 * You should redirect the user to identity update password page, there he will fill up form and click update button\
 * After success update inside the identity he will be redirected to the last page in your application
 *
 * @returns {string} url to the internal update password identity page
 * @event AUTH_EVENTS_TYPES.UPDATE_PASSWORD_REQUEST is fired before the return clause
 * @example
 *
 * window.location = updatePassword()
 * <a href={updatePassword()}>Update password</a>
 */
export const updatePassword = (): string => {
  if (!isConfigured('updatePassword')) return window.location.href;

  const [updatePasswordUrl, updatePasswordParams] = identityConfig.updatePassword();
  const params = qs.stringify(updatePasswordParams as Record<string, any>);

  callbackListeners[AUTH_EVENTS_TYPES.UPDATE_PASSWORD_REQUEST]();

  return `${updatePasswordUrl}?${params}`;
};

/**
 * Reset password inside identity through their web interface\
 * Both identities have support for forgot existing user password\
 * You should redirect the user to identity forgot password page, there he will fill up his email and click submit button\
 * After success he will receive email with confirmation link to which he should click and fill up password and confirm password
 *
 * @returns {string} url to the internal forgot password identity page
 * @event AUTH_EVENTS_TYPES.FORGOT_PASSWORD_REQUEST is fired before the return clause
 * @example
 *
 * window.location = forgotPassword()
 * <a href={forgotPassword()}>Forgot password</a>
 */
export const forgotPassword = (): string => {
  if (!isConfigured('forgotPassword')) return window.location.href;

  const [forgotPasswordUrl] = identityConfig.forgotPassword();

  callbackListeners[AUTH_EVENTS_TYPES.FORGOT_PASSWORD_REQUEST]();

  return forgotPasswordUrl;
};
