/* eslint-disable camelcase */
import { requestAdapter, snakeCase, isFunction, mapKeys, pick, isPlainObject } from '@veraio/core';
import {
  ConfigMethodReturn,
  AuthenticateConfig,
  AuthEventTypesValues,
  IdentityConfig,
  EventCallbacks,
  InternalProviderConfig,
} from './interfaces';
import { CONFIG_IDENTITY_OPTIONS, AUTH_EVENTS_TYPES } from './enums';

let configurationDone = false;
export let oneLifeApiDomain = '';
export let requestInstance = requestAdapter();
export let storageInstance = window.localStorage;
export const xFromHeader = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
export const storageKey = 'token';

export let identityConfig: InternalProviderConfig = {
  domain: window.origin,
  client_id: '',
  redirect_uri: `${window.origin}/authorization-callback`,
  post_logout_redirect_uri: `${window.origin}/logout`,
  scope: 'OneLife_api openid offline_access',
  // Declare all endpoints which should be called
  register: (): ConfigMethodReturn => [
    `${identityConfig.domain}/protocol/openid-connect/registrations`,
    { ...pick(identityConfig, ['client_id', 'scope', 'redirect_uri']), response_type: 'code', kc_action: 'REGISTER' },
  ],
  login: (): ConfigMethodReturn => [`${identityConfig.domain}/protocol/openid-connect/auth`],
  loginWithPassword: (): ConfigMethodReturn => [`${oneLifeApiDomain}/users/login`],
  exchangeCodeForToken: (): ConfigMethodReturn => [`${identityConfig.domain}/protocol/openid-connect/token`],
  updatePassword: (): ConfigMethodReturn => [
    `${identityConfig.domain}/protocol/openid-connect/auth`,
    {
      ...pick(identityConfig, ['client_id', 'scope', 'redirect_uri']),
      response_type: 'code',
      kc_action: 'UPDATE_PASSWORD',
    },
  ],
  forgotPassword: (): ConfigMethodReturn => [`${identityConfig.domain}/login-actions/reset-credentials`],
  refreshToken: (): ConfigMethodReturn => [`${identityConfig.domain}/protocol/openid-connect/token`],
  logout: (): ConfigMethodReturn => [`${identityConfig.domain}/protocol/openid-connect/logout`],
};

/**
 * To use this package you must for sure configure the domains, client id and client secrets\
 * Both identities work with configured domain and client id as required params\
 * Identity server should have a client secret as additional param to domain and client id
 *
 * @param options AuthenticateConfig object which should have 2 properties with values 2 config objects for bth identities
 * @see AUTH_EVENTS_TYPES for more details about event type
 * @see AuthenticateConfig
 * @example
 *
 * setAuthConfig({
 *    identity: { domain: {identityUrl}, clientId: {identityClientId} },
 *    oneLifeApiDomain: {lifeDomain}
 *    onRegister: () => 'Register is successful',
 * });
 */
export const setAuthConfig = (options: AuthenticateConfig): void => {
  const { identity, oneLifeDomain, requestAdapter, storage, ...rest } = options;
  configurationDone = true;

  const getUserConfig = (config: IdentityConfig) =>
    mapKeys(pick(config, CONFIG_IDENTITY_OPTIONS), (_: any, key: string) => snakeCase(key));

  identityConfig = { ...identityConfig, ...getUserConfig(identity) };
  oneLifeApiDomain = oneLifeDomain;
  storageInstance =
    isPlainObject(storage) &&
    isFunction(storage.getItem) &&
    isFunction(storage.setItem) &&
    isFunction(storage.removeItem)
      ? storage
      : storageInstance;
  if (requestAdapter) requestInstance = requestAdapter;
  const eventKeys: AuthEventTypesValues = Object.values(AUTH_EVENTS_TYPES);
  Object.entries(rest).forEach(([key, val]) => {
    const validKey = key as AuthEventTypesValues[number];
    if (eventKeys.includes(validKey) && isFunction(val)) callbackListeners[validKey] = val;
  });
};

/**
 * Check if there is configuration done through setAuthConfig method
 *
 * @param methodName which method is called
 * @returns {boolean} true if the configuration is done
 * @example
 *
 * isConfigured('updatePassword'); // false
 * setAuthConfig({ ... })
 * isConfigured('updatePassword'); // true
 */
export const isConfigured = (methodName: string): boolean => {
  !configurationDone &&
    console.error(
      `In order to use ${methodName} method from @oneecosystem/authenticate, you should first call setAuthConfig method on root level in your application. See setAuthConfig documentation about more information how to use it`,
    );

  return configurationDone;
};

/**
 * Event listeners for each event which need to be executed
 */
export const callbackListeners: EventCallbacks = {
  [AUTH_EVENTS_TYPES.REGISTER_REQUEST]: () => {},
  [AUTH_EVENTS_TYPES.REGISTER]: () => {},
  [AUTH_EVENTS_TYPES.LOGIN_REQUEST]: () => {},
  [AUTH_EVENTS_TYPES.LOGIN]: () => {},
  [AUTH_EVENTS_TYPES.LOGIN_WITH_PASSWORD]: () => {},
  [AUTH_EVENTS_TYPES.LOGOUT_REQUEST]: () => {},
  [AUTH_EVENTS_TYPES.LOGOUT]: () => {},
  [AUTH_EVENTS_TYPES.UPDATE_PASSWORD_REQUEST]: () => {},
  [AUTH_EVENTS_TYPES.FORGOT_PASSWORD_REQUEST]: () => {},
  [AUTH_EVENTS_TYPES.REFRESH_TOKEN]: () => {},
  [AUTH_EVENTS_TYPES.RENEW_SESSION]: () => {},
  [AUTH_EVENTS_TYPES.SET_TOKEN]: () => {},
};
