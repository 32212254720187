import { config } from '../config';
import { Currency, OneLifeConfig } from '../interfaces';

export const getCurrenciesExchangeRates = () =>
  config.requestInstance.get<Currency[]>(`${config.dealshakerPortalApiDomain}/api/Currencies/GetLatestCurrencyRates`);

export const getCoinExchangeRate = () =>
  config.requestInstance.get<OneLifeConfig>(`${config.oneLifeApiDomain}/config/get`, {
    params: { key: 'CashForCoinExchangeRate' },
  });
