import { config } from '../config';
import { UserAddress } from '../interfaces';

const baseUrl = () => `${config.dealshakerPortalApiDomain}/api/UserAddresses`;

export const addNewAddress = (data: UserAddress) =>
  config.requestInstance?.post<UserAddress>(`${baseUrl()}/Create`, data);

export const updateDeliveryAddress = (data: UserAddress) =>
  config.requestInstance?.put<UserAddress>(`${baseUrl()}/Update`, data);

export const setDefaultAddress = (addressId: number) =>
  config.requestInstance?.patch<UserAddress>(`${baseUrl()}/SetDefault`, { addressId });

export const updateDeliveryInstructions = (addressId: number, deliveryInstructions: string) =>
  config.requestInstance?.patch<UserAddress>(`${baseUrl()}/UpdateDeliveryInstructions`, {
    addressId,
    deliveryInstructions,
  });

export const deleteDeliveryAddress = (addressId: number) =>
  config.requestInstance?.delete(`${baseUrl()}/Delete/${addressId}`);
