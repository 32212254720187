import { requestAdapter } from '@veraio/core';
import { Language, TranslationsResponse } from './interfaces';

const apiUrl = 'https://strank.oneecosystem.eu/api';

const { get } = requestAdapter();

export const getAllTranslations = (environmentCode: string, params = {}) =>
  get<TranslationsResponse>(`${apiUrl}/environment/${environmentCode}/translations`, { params });

export const getAllLanguages = () => get<Language[]>(`${apiUrl}/languages`);
