import { useState, useEffect, useDebugValue } from 'react';
import { Store } from './store';
import { deepEqual, isFunction } from '../utils';

export type SelectorFunction<State> = (state: State) => Partial<State>;

/**
 * CreateStore method is based on vanillaJS which is not very compatible with react.\
 * This method is adapter for createStore to be plugged into react component\
 * It accept the store to be used and selectorFunction used to subscribe for part of the state\
 * It is inspired from redux and zustand to have selector function which should return a part from the state\
 * Only when this part of the state is changed the component will re-render
 *
 * @param store store object returned from createStore method to be used
 * @param selectorCallback optional function to select part from the state which is needed
 * @returns store value
 * @see createStore
 * @example
 *
 * const userStore = createStore({ firstName: 'John', lastName: 'Doe' });
 * const useUser = (selector) => useStore(userStore, selector);
 * const PartialRenderComponent = (props) => {
 *  const userFirstName = useUser(userState => userState?.firstName) // this component will re-render only if first name is changed
 * };
 *  const FullRenderComponent = (props) => {
 *  const user = useUser() // this component will re-render on every change of the user state
 * // user is object with { firstName: 'John', lastName: 'Doe' }
 * };
 */
// eslint-disable-next-line comma-spacing
export const useStore = <T,>(store: Store<T>, selectorCallback: SelectorFunction<T>): T => {
  const selector = isFunction(selectorCallback) ? selectorCallback : (state: any) => state;
  const value = selector(store.getState());

  const [{ inst }, forceUpdate] = useState({ inst: { value: store.getState(), getSnapshot: store.getState } });

  useEffect(() => {
    if (!deepEqual(selector(inst.value), selector(inst.getSnapshot())))
      forceUpdate({ inst: { value: store.getState(), getSnapshot: store.getState } });

    const handleStoreChange = (nextState: any, prevState: any) => {
      if (!deepEqual(selector(nextState), selector(prevState)))
        forceUpdate({ inst: { value: store.getState(), getSnapshot: store.getState } });
    };

    return store.subscribe(handleStoreChange);
  }, [store.subscribe]);

  useDebugValue(value);
  return value;
};
