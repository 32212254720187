import { isNumber } from '@veraio/core';
import { round, sub, mul, div } from 'exact-math';
import { Deal } from '../interfaces';

export const trimZerosFromString = (string: string): string => string.replace(/\.?0+$/, '');

/**
 * Convert an amount of fiat to human readable format of 2 digits after decimal point
 *
 * @param amount fiat amount which is not human readable - 4, 1.4567
 * @example
 *
 * roundFiat(42.5) // 42.5
 * roundFiat(42.5123444) // 42.51
 */
export const roundFiat = (amount: number): number => (isNumber(amount) ? round(amount, -15) : 0);

/**
 * Convert an amount of crypto to human readable format of 4 digits after decimal point
 *
 * @param amount crypto amount which is not human readable - 4, 1.4567
 * @example
 *
 * roundCrypto(42.5) // 42.5
 * roundCrypto(42.5123444) // 42.5123
 */
export const roundCrypto = (amount: number): number => (isNumber(amount) ? round(amount, -15) : 0);

/**
 * Stringify an amount to human readable format. Round to the desired digit after decimal.\
 * Or round to the first non-zero digit.
 *
 * @param amount amount which is not human readable - 1.4567782892, 0.000000006
 * @param digitAfterDecimal how many digits after decimal to be displayed, default is 4
 * @example
 *
 * stringifyToFirstNonZeroDigit(42.5123876) // 42.5123
 * stringifyToFirstNonZeroDigit(0.1499, 2) // 0.14
 * stringifyToFirstNonZeroDigit(0.000000017512, 2) // 0.00000001
 */
export const stringifyToFirstNonZeroDigit = (amount: number, digitAfterDecimal = 4): string => {
  const stringAmount = trimZerosFromString(amount.toFixed(15).toString());

  let roundToDigit = digitAfterDecimal;
  if (parseInt(stringAmount, 10) === 0) {
    // Find with regex the first non zero digit, it can return null if there is no such
    // It is searching for .00001, and it will return the string with . in front, thats why we reduce the length with 1
    // If there is no match null - 1 will return NaN, thats why we have ?? 1, so it will return real value
    const firstNonZeroDigit = (stringAmount.match(/\.0+[1-9]/)?.[0]?.length ?? 1) - 1;
    roundToDigit = Math.max(firstNonZeroDigit, digitAfterDecimal);
  }

  return trimZerosFromString(round(amount, -roundToDigit).toFixed(roundToDigit));
};

/**
 * Dealshaker API send every deal with 3 main properties - price, discount and percentRatio\
 * Based on those 3 main parameters we need to calculate - price (fiat and crypto), discounted prices (total, fiat and crypto)\
 * Those new properties are added to the deal object
 *
 * @param deal deal object for specific deal coming from API
 * @example
 *
 * calculateDealPrice({ price: 100, percentRatio: 50, discount: 0, ... }) // { priceFiat: 50, priceCrypto: 50, discountedPrice: null ... }
 * calculateDealPrice({ price: 100, percentRatio: 50, discount: 50, ... }) // { priceFiat: 50, priceCrypto: 50, discountedPrice: 50, discountedPriceFiat: 25 ... }
 */
export const calculateDealPrice = (deal: Deal): Deal => {
  const discountedPrice = roundFiat(sub(deal.price, mul(div(deal.discount, 100), deal.price)));
  const hasDiscount = deal.discount > 0;
  const hasFiatPrice = deal.percentRatio < 100;

  return {
    ...deal,
    price: deal.price,
    priceCrypto: roundCrypto(mul(div(deal.percentRatio, 100), deal.price)),
    priceFiat: hasFiatPrice ? roundFiat(mul(div(sub(100, deal.percentRatio), 100), deal.price)) : null,
    discountedPrice: hasDiscount ? discountedPrice : null,
    discountedPriceCrypto: hasDiscount ? roundCrypto(mul(div(deal.percentRatio, 100), discountedPrice)) : null,
    discountedPriceFiat:
      hasDiscount && hasFiatPrice ? roundFiat(mul(div(sub(100, deal.percentRatio), 100), discountedPrice)) : null,
  };
};

/**
 * Calculate what is the part of this promo code and subtract it from original price, the deal MUST be with calculated prices
 *
 * @param deal deal object for specific deal coming from API
 * @param percentage optional parameter in range from 0.01 to 1, if not provided return null to all prices
 * @see calculateDealPrice method
 * @example
 *
 * calculateDealPriceWithPromoCode({ priceFiat: 50, priceCrypto: 50 ... }, 20) // { discountedPriceFiatWithPromo: 40, discountedPriceCryptoWithPromo: 40 ... }
 * calculateDealPriceWithPromoCode({ priceFiat: 50, priceCrypto: 50 ... }) // { discountedPriceFiatWithPromo: null, discountedPriceCryptoWithPromo: null ... }
 */
export const calculateDealPriceWithPromoCode = (deal: Deal, percentage?: number | null): Deal => {
  const hasPromo = isNumber(percentage);
  const price = deal.discountedPrice ?? deal.price;
  const priceCrypto = deal.discountedPriceCrypto ?? deal.priceCrypto;
  const priceFiat = deal.discountedPriceFiat ?? deal.priceFiat;

  return {
    ...deal,
    discountedPriceWithPromo: hasPromo ? roundFiat(sub(price, mul(price, percentage))) : null,
    discountedPriceCryptoWithPromo: hasPromo ? roundCrypto(sub(priceCrypto, mul(priceCrypto, percentage))) : null,
    discountedPriceFiatWithPromo: hasPromo && priceFiat ? roundFiat(sub(priceFiat, mul(priceFiat, percentage))) : null,
  };
};

/**
 * Calculate what are the total price, priceCrypto and priceFiat for deal\
 * If there is discounted*WithPromo price take it, if not take discounted* price if there is not return regular price
 *
 * @param deal deal object for specific deal with already calculated prices with calculateDealPrice method
 * @see calculateDealPrice method
 */
export const getDealFinalPrices = (deal: Deal): Pick<Deal, 'price' | 'priceCrypto' | 'priceFiat'> => ({
  price: deal?.discountedPriceWithPromo ?? deal.discountedPrice ?? deal.price,
  priceCrypto: deal?.discountedPriceCryptoWithPromo ?? deal.discountedPriceCrypto ?? deal.priceCrypto,
  priceFiat: deal?.discountedPriceFiatWithPromo ?? deal.discountedPriceFiat ?? deal.priceFiat,
});
