import { Continent } from '../../interfaces';

export const getContinentsResponse = (data: Continent[]): Continent[] =>
  data.map((continent) => ({
    ...continent,
    countries: continent?.countries?.map((country) => ({
      ...country,
      continentId: continent.id,
      areas: country?.areas?.map((area) => ({ ...area, countryId: country.id, continentId: continent.id })),
    })),
  }));
